<template>
    <v-app>
        <v-dialog max-width="600" persistent v-model="dialog">

            <v-card>

              <v-toolbar dark>
                <v-card-title class="headline">
                  {{ `${edit ? 'Update' : 'Add'}` }}  printer
                  <hr>
                </v-card-title>

                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-spacer></v-spacer>
                  <v-btn icon  @click="resetForm">
                    <i class="fas fa-times"></i>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>

                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                    dense
                                    label=" Title"
                                    outlined
                                    v-model="printer.title"
                            >

                            </v-text-field>
                            <span class="text-danger" v-if="$v.printer.title.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12">
                            <v-autocomplete
                                    :items="users"
                                    dense
                                    item-text="full_name"
                                    item-value="id"
                                    label="User"
                                    outlined
                                    v-model="printer.user_id"
                            >

                            </v-autocomplete>
                          <span class="text-danger" v-if="$v.printer.user_id.$error">This information is required</span>

                        </v-col>
                        <v-col cols="12">
                            <label for="printer">Token for Printer</label>
                            <h3>{{ printer.token }}</h3>

                        </v-col>
                        <v-col cols="3">
                            <v-switch
                                    label="Default"
                                    v-model="printer.is_default"
                            >
                            </v-switch>
                        </v-col>
                        <v-col cols="3">
                            <v-switch
                                    label="Active"
                                    v-model="printer.is_active"
                            >
                            </v-switch>
                        </v-col>
                    </v-row>


                    <div class="col-12 text-right mt-4">
                        <v-btn
                                @click="resetForm"
                                class="btn btn-standard cancel-btn"
                                depressed
                        >Cancel
                        </v-btn>
                        <v-btn   v-if="checkIsAccessible('printer', 'create')"
                                :loading="isBusy"
                                @click="createAndUpdate"
                                class="text-white ml-2 btn btn-primary"
                                depressed
                        >Save
                        </v-btn>

                    </div>
                </v-card-text>

            </v-card>
        </v-dialog>
    </v-app>
</template>
<script>
    import {required} from "vuelidate/lib/validators";
    import PrinterService from "@/core/services/printer/PrinterService";
    import UserService from "@/core/services/user/UserService";

    const printerService = new PrinterService();
    const userService = new UserService();

    export default {
        props: ["message_id"],
        name: "printer-create-and-update",
        validations: {
            printer: {
                title: {required},
                user_id: {required}
            }
        },

        data() {
            return {
                dialog: false,
                isBusy: false,
                edit: false,
                page: null,
                users: [],
                search: {
                    type: 'all',
                    limit: 25
                },
                printer: {
                    title: null,
                    token: null,
                    user_id: null,
                    is_default: null,
                    is_active: true
                }
            };
        },
        computed: {},
        methods: {
            randomToken() {
                const rand = () => Math.random(0).toString(36).substr(2);
                const token = (length) => (rand() + rand() + rand() + rand()).substr(0, length);
                return token(40);
            },
            getUsers() {
                userService.paginate(this.search, this.page).then(response => {
                    this.users = response.data.data;
                    // alert(this.length)
                });
            },
            get(id) {
                printerService.show(id).then(response => {
                    this.edit = true;
                    this.printer = response.data.printer;
                    this.printer.is_active = this.printer.is_active ? true : false;
                });
            },
            showModal(id = null) {
                if (id) {
                    this.get(id);
                } else {
                    this.printer.token = this.randomToken();
                }
                this.dialog = true;
                this.getUsers();

            },
            hideModal() {
                this.teacher = [];
                this.dialog = false;
            },
            toggleModal() {
                // We pass the ID of the button that we want to return focus to
                // when the modal has hidden
                this.$refs["my-modal"].toggle("#toggle-btn");
            },
            createAndUpdate() {
                this.$v.$touch();
                if (this.$v.printer.$error) {
                    setTimeout(() => {
                        this.$v.$reset();
                    }, 3000);
                } else {
                    if (this.edit) {
                        this.update();
                    } else {
                        this.create();
                    }
                }
            },
            create() {
                this.isBusy = true;
                printerService.store(this.printer).then(response => {
                    this.resetForm()
                    this.dialog = false;
                    this.isBusy = false;
                    this.$snotify.success("Information added");
                    this.$emit("refresh");
                }).catch(error=>{
                  this.$snotify.error("Something went wrong");
                }).finally(() => {
                    this.isBusy = false;
                });
            },
            update() {
                this.isBusy = true;
                printerService.update(this.printer.id, this.printer).then(response => {
                    this.resetForm()
                    this.dialog = false;
                    this.isBusy = false;
                    this.$snotify.success("Information updated");
                    this.$emit("refresh");
                }).catch(error=>{
                  this.$snotify.error("Something went wrong");
                }).finally(() => {
                    this.isBusy = false;
                });
            }, resetForm() {
                this.printer = {
                    title: null,
                    total_printer: null,
                    is_recurring: null,
                    student_cancellable: null,
                    token: null,
                    is_active: true
                }
                this.edit = false;
                this.dialog = false;
                this.$v.$reset()
            }
        }
    };
</script>
