<template>
    <v-app>
      <div class="row">
        <div class="col-12">
            <div class="card">
              <div class="card-header">
                <div class="card-title pt-3 px-3 d-flex justify-content-between">
                  <div class="breadcrumb-left">
                    <h4>
                      Manage printers
                    </h4>
                    <div class="breadcrumb-sub-header">
                      <router-link to="/dashboard">Dashboard </router-link>\  Printers
                    </div>
                  </div>
                  <div class="breadcrumb-right">
                    <v-btn v-if="!loading && checkIsAccessible('printer', 'create')"
                           @click="openDialog()"
                           class="mt-4 btn btn-primary  mr-1"
                           dark>
                      <i class="fa fa-plus"></i>
                      Add printer
                    </v-btn>

                  </div>
                </div>
              </div>
            <div class="card-body">
              <div class="">
                <v-skeleton-loader
                    type="table-thead"
                    v-if="loading"
                >
                </v-skeleton-loader>

                <v-skeleton-loader
                    v-if="loading"
                    type="table-row-divider@25"
                >
                </v-skeleton-loader>
                <table class="table" v-if="!loading">
                  <thead>
                  <tr>
                    <th class="px-3 wrap-column">Title</th>
                    <th class="px-3 wrap-column">User</th>
                    <th class="px-3 wrap-column">Default</th>
                    <th class="px-1">Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-show="printers.length > 0" v-for="(printer, index) of printers" :key="index">
                    <td class="px-3 wrap-column">
                      <a href="#" @click="openDialog(printer.id)" class="mr-2">
                        {{printer.title}}
                      </a>
                      <i class="fas fa-circle"
                         :class="printer.is_active?'dot-active':'dot-inactive'"></i></td>
                    <td class="px-3 wrap-column">{{printer.user? `${printer.user.first_name} ${printer.user.last_name}` :
                        "N/A"}}
                    </td>
                    <!--                                            <td>{{printer.token}}</td>-->
                    <td class="px-3 wrap-column">
                                              <span
                                                  class="badge"
                                                  :class="printer.is_default?'badge-success':'badge-danger'"
                                              >{{printer.is_default?'Yes':'No'}}</span>
                    </td>

                    <td>
                      <b-dropdown
                          size="sm"
                          variant="link"
                          toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                          no-caret
                          right
                          no-flip
                      >
                        <template v-slot:button-content>
                          <slot>
                                                        <span>
                                                                    <i class="flaticon-more-1"></i>
                                                                  </span></slot>
                        </template>
                        <!--begin::Navigation-->
                        <div class="navi navi-hover ">

                          <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('printer', 'edit')">
                            <a href="#" class="navi-link"
                               @click="openDialog(printer.id)">
                              <span class="navi-icon">
                                <i class="flaticon-edit"></i>
                              </span>
                              <span class="navi-text">  Edit</span>
                            </a>
                          </b-dropdown-text>
                          <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('printer', 'delete')">
                            <a href="#" class="navi-link"
                               @click="deletePrinter(printer.id)">
                                                                                <span class="navi-icon">
                                                                                  <i class="fas fa-trash"></i>
                                                                                </span>
                              <span class="navi-text">  Delete</span>
                            </a>
                          </b-dropdown-text>

                        </div>
                        <!--end::Navigation-->
                      </b-dropdown>

                    </td>
                  </tr>
                  <tr v-if="printers.length == 0">
                    <td colspan="4" class="text-center">
                      <strong>
                        No data available to display.
                      </strong>

                    </td>
                  </tr>
                  </tbody>
                </table>
                <b-pagination
                    v-if="printers.length > 0"
                    class="pull-right mt-7"
                    @input="getPrinters"
                    v-model="page"
                    :total-rows="total"
                    :per-page="perPage"
                    first-number
                    last-number
                ></b-pagination>
              </div>

            </div>
            <create-and-update ref="printer" :printer_id="printer_id" @close_dialog="closeDialog"
                               @refresh="getPrinters"></create-and-update>
        </div>
        </div>
      </div>
    </v-app>
</template>
<script>

    import CreateAndUpdate from "./CreateAndUpdate";
    import PrinterService from "@/core/services/printer/PrinterService";

    const printerService = new PrinterService();

    export default {
        name: "Printer",
        components: {
            CreateAndUpdate
        },
        data() {
            return {
                printers: [],
                printer_id: null,
                page: null,
                total: null,
                perPage: null,
                loading:false
            };
        },
        mounted() {
            this.getPrinters();
        },
        methods: {
            getPrinters() {
              this.loading=true;
                printerService.paginate().then(response => {
                    this.printers = response.data.data;
                    this.page = response.data.meta.current_page;
                    this.total = response.data.meta.total;
                    this.perPage = response.data.meta.per_page;
                    this.loading=false
                });
            },
            openDialog(id) {
                this.printer_id = id;
                this.$refs["printer"].showModal(id);
            },
            closeDialog(id) {
                this.printer_id = id;
                this.$refs["printer"].hideModal();
                this.getFaculties();
                this.printer_id = null;
            },
            deletePrinter(id) {
                this.$confirm({
                    message: `Are you sure you want to delete this item ?`,
                    button: {
                        no: "No",
                        yes: "Yes"
                    },
                    callback: confirm => {
                        if (confirm) {
                            printerService.delete(id).then(response => {
                                this.$snotify.success("Item Deleted");
                                this.getPrinters();
                            });
                        }
                    }
                });
            }
        }
    };
</script>
